import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {HeaderDataService} from "../../services/header_data.service";
import {DateTimePeriodService} from "../../services/date-time-period.service";
import {Model} from "../../services/api/model";

@Component({
    selector: 'audit-history',
    templateUrl: './audit-history.component.html',
    styleUrls: ['audit-history.component.less'],
    standalone: false
})
export class AuditHistoryComponent implements OnInit {

    audited_models: Model[];
    selected_models: Model[] = [];
    auditedModelOptions: string[];

    constructor(private api: ApiService,
                private headerDataService: HeaderDataService,
                private dateTimePeriodService: DateTimePeriodService) {
    }

    modelCompare = (a: string, b: Model) => {
        return a === b.modelName;
    }

    ngOnInit() {
        this.dateTimePeriodService.show_time = true;
        this.dateTimePeriodService.show_timespan = false;
        this.headerDataService.show_dtp = true;

        this.headerDataService.title = 'Audit History';

        this.audited_models = this.api.mapApis.filter(item => item.audited).map(item => this.api[item.name]);
        this.auditedModelOptions = this.audited_models.map(m => m.modelName);
    }


    onModelSelect($event) {
        this.selected_models = this.audited_models.filter(m => $event.includes(m.modelName));
    }

    modelValueFunction(model: Model) {
        return model.modelName;
    }
}
